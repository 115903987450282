body {
    margin: 0;
}


/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
    transition: transform 300ms ease-in-out;
    transition-delay: 100ms;
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open {
    transform: scale(1);
    /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close {
    transform: scale(0);
    /* transform: translateY(-150%); */
}

a {
    text-decoration: none;
    /* color: #043792; */
    color: black;
    position: relative;
}

a.animated {
    position: relative;
}

a.animated:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #48bb78;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

a.animated:hover:after {
    width: 100%;
    left: 0;
}

tbody tr:nth-child(even) {
    background-color: #f3f3f3;
    color: #009878;
}

tbody tr:nth-child(odd) {
    background-color: white;
    color: rgb(36, 62, 99);
}

.modal::backdrop {
    background: hsl(0 0% 0% / 50%);
}

.modal {
    max-width: 80%;
    max-height: 80%;
    padding: 2rem;
    border: 0;
    border-radius: 0.5rem;
    position: relative;
    border-radius: 1rem;
    position: fixed;
    box-shadow: hsl(0 0% 0% / 10%) 0 0 0.5rem 0.25rem;
    border: 1px solid #ccc;
}

.modal-close-btn {
    font-size: 1em;
    position: absolute;
    top: 0.5em;
    right: 0.5em;
}

.carousel-title-text {
    text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
    0px 8px 13px rgba(0,0,0,0.1),
    0px 18px 23px rgba(0,0,0,0.1);
}